import React from "react"

import LandingBio from "../components/landing-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PodcastsPage = () => (
  <Layout>
    <SEO title="Podcasts" keywords={[`amy conroy`, `podcasts`]} />
    <div>
  <h2> Podcasts </h2>
  </div>
    <div>
      <h4><a href = "https://open.spotify.com/episode/7yG00DtPiB9X3l6MRHx78w?si=9d04e398062247f7">The Exploratory Journey</a></h4>
      <p>2022. Law School 2.0 with Amy Conroy, Annabel Pemberton and Nathan Corr.</p>
    </div>
    <div>
      <h4><a href = "https://podcasts.apple.com/us/podcast/law-school-2-0-ep-01/id1580385164?i=1000531576910">100% Legal</a></h4>
      <p>2021. Law School 2.0.</p>
    </div>
    <div>
      <h4><a href = "https://pod.link/1434998089/episode/b57fae09a88a286867738ae6ac6b4bea">Future Law Podcast</a></h4>
      <p>2021. LegalTech in London: Giles Thompson and Amy Conroy.</p>
    </div>
    <div>
      <h4><a href = "https://anchor.fm/the-law-objective">The Law Objective</a></h4>
      <p>2021. Talking Legal Tech.</p>
    </div>
    <div>
      <h4><a href = "https://open.spotify.com/episode/30FvJa6FMhsfPhtyouORKe?si=lf1ibAhaQoi3hMVPG4MQ6A">DomTalksTech</a></h4>
      <p>2021. Avvoka & Legal Tech.</p>
    </div>
    <div>
      <h4><a href = "https://open.spotify.com/episode/7Adaz28UsJsFYdRYbyINhd">The Wired Wig</a></h4>
      <p>2020. Law & Computer Science: Collective Action through Code.</p>
    </div>
    <div>
      <h4><a href = "https://open.spotify.com/episode/20LlYHxRYQ6Q9FtOq9LMed?si=SdYSu3aZSEa_FoRqWj299Q">The Wired Wig</a></h4>
      <p>2020. Applications & Internships in Law and Technology.</p>
    </div>
    <div>
      <h4><a href = "https://open.spotify.com/episode/6CPUcTtCOfOoNgD9jU0yLi?si=QTz-r3FeSvOzjFw2F8im-A">KIIPSPEAK</a></h4>
      <p>2020. openTenancy & Legal-Tech Solutions.</p>
    </div>
  <div>
  <h2> Recordings </h2>
  </div>
      <div>
      <h4><a href = "https://www.youtube.com/watch?v=5Ysk7D2NgD4">University of Bristol</a></h4>
      <p>July 2021. Student Speaker at the University of Bristol Engineering Virtual Celebration.</p>
    </div>
    <div>
      <h4><a href = "https://www.instagram.com/tv/CKRoSu0p175/?utm_source=ig_web_copy_link">LegalBelle</a></h4>
      <p>Jan 2021. LegalBelle: 5 at 5 IGTV.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=bOq-vNjH2cs">KIIPSODES: Youtube</a></h4>
      <p>Jan 2021. openTenancy Open-Source Law.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=pRrXY2KTbPQ">DomTalksTech</a></h4>
      <p>Jan 2021. Law, Legal Tech, Samsung Adverts, Google's Future & More!.</p>
    </div>
    <div>
      <h4><a href = "https://www.instagram.com/tv/CGA0L6WJM4m/">BamLegal</a></h4>
      <p>Oct 2020. BamBytes: Introducing openTenancy.</p>
    </div>
    <div>
      <h4><a href = "https://www.youtube.com/watch?v=QXn35bNYWTs">Law Made Easy</a></h4>
      <p>Oct 2020. Legal Tech Q&A.</p>
    </div>
  </Layout>


)

export default PodcastsPage
